import React from "react";
import { Button, Form, Input, message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { SendOTPAPI, VerifyOTPAPI } from "../../Constants/Api/Api";
import "./VerifyOtp.scss";
import { Container } from "react-bootstrap";
const VerifyOTP = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = location.state || {};
  const onFinish = (values) => {
    let formData = {
      email: email,
      otp: values.userotp,
      type: "email_varification",
    };
    VerifyOTPAPI(formData)
      .then((res) => {
        message.success(res?.data?.message);
        navigate("/signin");
      })
      .catch((error) => {
        message.error(error.response.data.message);
      });
  };
  const onFinishFailed = (errorInfo) => {};

  const handleResendOtp = () => {
    const data = {
      email: email,
      type: "email_varification",
    };
    SendOTPAPI(data)
      .then((res) => {
        message.success(res?.data?.message);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
  return (
    <Container className="VerifyOtp main-container">
      <div className="Main">
        <div className="Left"></div>
        <div className="Right">
          <div className="form_inner">
            <Form
              name="basic"
              layout="vertical"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <h6>
                OTP has been sent to your{" "}
                <span style={{ fontWeight: "bolder" }}>{email}</span>
              </h6>
              <Form.Item
                label="Enter OTP"
                name="userotp"
                rules={[
                  {
                    required: true,
                    // message: 'Please input your usermobile!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item>
                <p onClick={handleResendOtp} className="forgotpasswordtext">
                  Resend Otp
                </p>
                <Button htmlType="submit"  className="buttontheme">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default VerifyOTP;

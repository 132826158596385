import React, { useEffect } from 'react'
import BannerImage from "../../assets/banner-inner.jpg";
import "./Layout.scss"
const Layout = ({ children }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
}, [])
  return (
<div className='BannerImageLayout'>
      <div className='BAnnerImage'>
        <img src={BannerImage} alt='banner-inner' className='banner-inner' />
      </div>
      {children}
    
</div>
  )
}

export default Layout

// features/cart/cartSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetAllProductsInCart } from '../Constants/Api/Api';
export const fetchAllProductsInCart = createAsyncThunk(
  'cart/fetchAllProductsInCart',
  async (token) => {
    const response = await GetAllProductsInCart(token);
    return response.data;  // Extract only the data property from the Axios response
  }
);
const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    products: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    // Additional reducers can be added here for synchronous actions
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllProductsInCart.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllProductsInCart.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.products = action.payload;  // Use only the data property
      })
      .addCase(fetchAllProductsInCart.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});
export default cartSlice.reducer;

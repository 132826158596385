import React from "react";
import { Form, Input } from "antd";
import "./Forgot.scss";

const VerifyOtp = ({ formData, setFormData }) => {
  return (
    <div className="forgot_form">
      <div className="forgot_form_container">
        <Form layout="vertical">

          <Form.Item label="Email">
            <Input
              type="text"
              placeholder={formData.username}
              disabled
            />
          </Form.Item>
          <Form.Item label="OTP">
            <Input
              // type="number"
              placeholder="OTP"
              value={formData.otp}
              onChange={(e) =>
                setFormData({ ...formData, otp: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item label="Password">
            <Input.Password
              placeholder="New Password"
              disabled
            />
          </Form.Item>
          <Form.Item label="Confirm New Password">
            <Input.Password
              placeholder="Confirm New Password"
              disabled
            />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default VerifyOtp;

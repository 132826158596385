import React, { useEffect, useState } from 'react'
import ProductCard from '../ProductCard/ProductCard'
import { GetProductAPI, GetProductAPIwithToken } from '../../Constants/Api/Api'
import { Container } from 'react-bootstrap'
import "./ProductComponent.scss"
import SkeletonCard from '../Skeleton/Skeleton'
const ProductComponent = () => {
    const [product, setProduct] = useState()
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem("UserLoginToken");

    useEffect(() => {
        if (!token) {

            GetProductAPI().then((res) => {
                setLoading(false);
                setProduct(res?.data?.data)
            }).catch((error) => {
                console.log("error", error)
            })
        } else GetProductAPIwithToken(token).then((res) => {

            setLoading(false);
            setProduct(res?.data?.data)
        }).catch((error) => {
            console.log("error", error)


        })
    }, [token])

    return (
        <div className="ProductComponent">
            <Container className='main-container'>

                <div class="row row-cols-1 row-cols-md-5 row-cols-lg-5 row-cols-sm-1 all_cards" >
                    {loading ? <SkeletonCard /> : product?.length === 0 ? "no data found"
                        : product?.map((item, index) => {
                            return (
                                <ProductCard ProductId={item.id} Image={item?.product_attachments.length > 0 ? `${item?.product_attachments[0]?.file_name}` : undefined}
                                    Name={item.name} Price={item.price} Description={item.description} Size={item.size} Weight={item.weight}
                                    Stock={item.stock}
                                    Cart={item?.is_cart}
                                />
                            )
                        })}
                </div>
            </Container>
        </div>



    )
}
export default ProductComponent

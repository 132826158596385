import React, { useEffect, useState } from 'react';
import { Col, Container, Form, Modal, Row, Button } from 'react-bootstrap';
import { CiCirclePlus, CiCircleMinus } from "react-icons/ci";
import { Card, Input, Radio, Space, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllProductsInCart } from '../../redux/cartSlice';
import { AddAddressAPI, CreateOrderAPI, RemoveProduct, updateProductQuantity } from '../../Constants/Api/Api';
import CartImage from "../../assets/ShoppingCart.gif"
import { Image_URL } from '../../Constants/host';
import './CheckoutPage.scss';
import { getClinetProfile } from '../../redux/getClientProfileSlice';
import { Link, useNavigate } from 'react-router-dom';

const CheckoutPage = () => {
    const navigate=useNavigate()
    const dispatch = useDispatch();
    const Profile = useSelector((state) => state?.clientProfile?.clientProfile);
    const lastItemIndex = Profile?.user_address?.length - 1;
    const lastItem = Profile?.user_address?.[lastItemIndex]?.address;
    const [address, setAddress] = useState(lastItem || "");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [value, setValue] = useState(1);
    const token = localStorage.getItem("UserLoginToken");
    const product = useSelector((state) => state.cartReducerData?.products?.data);
    useEffect(() => {
        dispatch(fetchAllProductsInCart(token));
        dispatch(getClinetProfile(token));
        setAddress(lastItem)
    }, [dispatch, token, lastItem]);

    const handleProductQtn = (id, operation) => {
        updateProductQuantity(id, operation)
            .then(() => dispatch(fetchAllProductsInCart(token)))
            .catch((error) => message.error(error.response.data.message));
    };

    const handleRemoveProduct = (id) => {
        RemoveProduct(id)
            .then((res) => {
                dispatch(fetchAllProductsInCart(token));
                message.success(res.message);
            })
            .catch((error) => console.log(error));
    };

    const handleCreateOrder = () => {
        CreateOrderAPI(address, token)
            .then((res) => {
                message.success(res?.data?.message);
                navigate("/orders")
                dispatch(fetchAllProductsInCart(token));
            })
            .catch((error) => {
                console.log(error, "errrorrr")
            });
    };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onChange = (e) => {
        setValue(e.target.value);
        setAddress(e.target.value); // Update address when selecting from the list
    }

    const handleSubmittAddress = () => {
        if (value === 4) { // If 'More...' is selected
            AddAddressAPI(address, token)
                .then((res) => {
                    message.success(res?.data?.message)
                    dispatch(getClinetProfile(token))
                    handleClose()
                })
                .catch((e) => {
                    console.log(e, "error")
                })
        } else { // If address is selected from the list
            handleClose(); // Close modal
        }
    }
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, [])
    return (
        <div className='CheckoutPage'>
            {product?.cartItems?.length === 0 ? <div className="emptyCart">
                <img src={CartImage} alt="gif" />
                <p>Your Cart is empty</p>
                <Link to='/product-shopping'>
                    <button className="shoppingBtn" >Keep Shopping</button>
                </Link>
            </div> :

                <Container>
                    <h2>Checkout</h2>
                    <Row>
                        <Col sm={8}>
                            <Card className='Deliver_Card'>
                                <div className='top'>
                                    <h5>Deliver to:</h5>
                                    <h5 className='CHANGE' onClick={handleShow}>CHANGE</h5>
                                </div>
                                {/* <p>{address===undefined?"--":address}</p> */}
                                <p>{address}</p>
                                {/* Modal for selecting delivery address */}
                                <Modal show={show} onHide={handleClose} centered>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            <h6>Select Delivery Address</h6>
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Radio.Group onChange={onChange} value={value}>
                                            <Space direction="vertical">
                                                {Profile?.user_address?.map((item, index) => {
                                                    return (
                                                        <Radio value={item?.address} key={index}>{item?.address}</Radio>
                                                    )
                                                })}
                                                <Radio value={4}>More...</Radio>
                                            </Space>
                                        </Radio.Group>
                                        {value === 4 && <Input style={{ width: 100, marginLeft: 10 }} value={address} onChange={(e) => setAddress(e.target.value)} />}
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" className='buttontheme' onClick={handleClose}>Close</Button>
                                        <Button variant="primary" className='buttontheme'  onClick={() => {
                                            handleSubmittAddress()
                                        }}>Save Changes</Button>
                                    </Modal.Footer>
                                </Modal>
                            </Card>
                            {/* Shopping cart items */}
                            <div className='section'>
                                {product?.cartItems &&
                                    <Card className='ShoppingCard'>
                                        {product?.cartItems?.map((item, index) => (
                                            <div className='Shoppingproduct' key={index}>
                                                <div className='product'>
                                                    <div className='product_image'>
                                                        <img src={`${Image_URL}${item?.cart_product?.product_attachments[0]?.file_name}`} crossOrigin="anonymous" alt={item?.cart_product?.name} />
                                                    </div>
                                                    <div className='product_details'>
                                                        <h5>{item?.cart_product?.name}</h5>
                                                        <h5 className='amount'>${item?.cart_product?.price}</h5>
                                                    </div>
                                                    <div className='remove_div'>
                                                        <div className='qut'>
                                                            <div className='pointer' onClick={() => handleProductQtn(item?.id, "decrease")}><CiCircleMinus size={30} /></div>
                                                            <div className='amount_total_item'>{item?.total_items}</div>
                                                            <div className='pointer' onClick={() => handleProductQtn(item?.id, "increase")}><CiCirclePlus size={30} /></div>
                                                        </div>
                                                        {/* <br /> */}
                                                        {/* <h5 onClick={() => handleRemoveProduct(item?.id)}>Remove</h5> */}
                                                        {/* <Link to=""> */}
                                                        <h6 onClick={() => handleRemoveProduct(item?.id)} className='remove_button'>Remove</h6>
                                                        {/* </Link> */}
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                        ))}
                                    </Card>
                                }
                            </div>
                        </Col>
                        {product?.totalPrice &&
                            <Col className='place_order_section'>
                                <Card>
                                    <h6>PRICE DETAILS</h6>
                                    <hr />
                                    <div className='priceitem'>
                                        <div>Price ({`${product?.cartItems?.length} items`})</div>
                                        <div>₹{product?.totalPrice}</div>
                                    </div>
                                    <br />
                                    <div className='priceitem'>
                                        <h5 className='total_amount'>Total Amount</h5>
                                        <h5 className='total_amount'>₹{product?.totalPrice}</h5>
                                    </div>
                                    <br />
                                    <Button onClick={handleCreateOrder} className='buttontheme'>Place Order</Button>
                                    {/* Modal for placing order */}
                                    <Modal title="Checkout Item" open={isModalOpen} onOk={handleCreateOrder} onCancel={() => setIsModalOpen(false)}>
                                        <h5>${product?.totalPrice}</h5>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>Address</Form.Label>
                                            <Form.Control as="textarea" rows={3} value={address} onChange={(e) => setAddress(e.target.value)} />
                                        </Form.Group>
                                    </Modal>
                                </Card>
                            </Col>
                        }
                    </Row>
                </Container>}
        </div>
    );
};
export default CheckoutPage;

import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick';
import { Container } from 'react-bootstrap';
import Line from "../../assets/line.jpg"
import "./BlogComponent.scss"
import { useNavigate } from 'react-router-dom';
import usePagination from '../Pagination/Pagination';
import { GetAllBlogs } from '../../Constants/Api/Api';
import { Image_URL } from '../../Constants/host';
import moment from 'moment';
import { MdOutlineChevronLeft, MdOutlineChevronRight } from 'react-icons/md';
import BlogCardComponent from '../CardComponent/BlogCardComponent';
const BlogComponent = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    rows: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 768, // Medium devices (tablets, 768px and up)
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480, // Small devices (phones, 480px and up)
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }]
  }
  const sliderRef = useRef(null);
  const next = () => {
    sliderRef.current.slickNext();
  };
  const previous = () => {
    sliderRef.current.slickPrev();
  };
  const [blog, setBlog] = useState()
  const navigate = useNavigate()
  let [page, setPage] = useState(1);
  const PER_PAGE = 12;
  const [Data, setData] = useState([]);
  const [data_count, setCount] = useState();
  const [loading, setLoading] = useState(true);
  const _DATA = usePagination(Data, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  // BlogByCategoryApi(slug, PER_PAGE, page)
  useEffect(() => {
    GetAllBlogs(PER_PAGE, page).then((res) => {
      setBlog(res?.data?.data?.rows)
      setLoading(false);
    }).catch((e) => {
      console.log(e, "error")
    })
  }, [PER_PAGE, page])
  const handleRedirectBlogDetails = (data) => {
    navigate("/blogs-details", {
      state: {
        data: data,
      }
    })
  }
  return (
    <Container className='BlogComponent'>
      <h3 className='h3heading'>
        OUR BLOG PAGE
      </h3>
      <div
        className="preNextButton"
        style={{
          display: "flex",
          justifyContent: "end",
          width: "auto",
          backgroundColor: "transparent",
          marginTop: "-70px"
        }}
      >
        <button className="preMoreBtn" onClick={previous}>
          <MdOutlineChevronLeft />
        </button>
        <button className="preMoreBtn" onClick={next}>
          <MdOutlineChevronRight />
        </button>
      </div>
      <div className='blogs_for_home'>
        <Slider ref={sliderRef} {...settings}>
          {blog?.map((item, index) => {
            return (
              <div  key={index}>
                <BlogCardComponent Image={`${Image_URL}${item?.blog_attachments[0]?.file_name}`} Title={item?.heading.split(' ').slice(0, 3).join(' ')} Date={moment(item?.created_at).fromNow()} Description={item.description.replace(/<h3>&nbsp;<\/h3>/g, '').split(/\s+/).slice(0, 35).join(' ')} blogId={item?.id}/>
              </div>
            )
          })}
        </Slider>
      </div>
    </Container >
  )
}
export default BlogComponent

import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SendOtp from "./SendOtp";
import VerifyOtp from "./VerifyOtp";
import ChangePassword from "./ChangePassword";
import { useState } from "react";
import { Button } from "react-bootstrap";
import {  useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { ForgotPasswordApi, SendOTPAPI, VerifyOTPAPI } from "../../Constants/Api/Api";
import { message } from "antd";
import "./forgotPassword.scss"
const steps = [
  "Select campaign settings",
  "Create an ad group",
  "Create an ad",
];
export default function ForgotPassword() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [tokenStore, setTokenStore] = useState()
  const [formData, setFormData] = useState({
    username: "",
    otp: "",
    password: "",
    confirmPassword: "",
  });
  const totalSteps = () => {
    return steps.length;
  };
  const completedSteps = () => {
    return Object.keys(completed).length;
  };
  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };
  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };
  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ?
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };
  const navigate = useNavigate();
  const Navigation = useCallback(() => {
    let value = "/signin";
    navigate(value);
  }, [navigate]);
  const handleVerifyOTP = () => {
    let Data = {
      email: formData.username,
      otp: formData.otp,
      type: "forgot_password"
    }
    VerifyOTPAPI(Data)
      .then((res) => {
        message.success(res?.data?.message)
        setTokenStore(res?.data?.data?.token)
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
      })
      .catch((error) => {
        message.error(error?.response?.data?.message)
        console.log(error, 'error')
      });
  };
  const handleChangePassword = () => {
    const data = {
      email: formData.username,
      password: formData.password,
      confirm_password: formData.confirmPassword,
      token: tokenStore
    }
    ForgotPasswordApi(
      data
    )
      .then((res) => {
        message.success(res?.data?.message)
        setTimeout(() => {
          Navigation();
        }, 2000);
      })
      .catch((error) => {
        console.log(error, 'error');
      });
  };
  const handleSendOTP = () => {
    let Data = {
      email: formData.username,
      type: "forgot_password"
    }
    SendOTPAPI(Data)
      .then((res) => {
        message.success(res?.data?.message)
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
      })
      .catch((error) => {
        console.log("error", error)
      });
  };
  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
}, [])
  return (
    <div className="forgot_form-1">
      {allStepsCompleted() ? (
        <React.Fragment>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
            {activeStep === 0 && (
              <SendOtp formData={formData} setFormData={setFormData} />
            )}
            {activeStep === 1 && (
              <VerifyOtp formData={formData} setFormData={setFormData} />
            )}
            {activeStep === 2 && (
              <ChangePassword formData={formData} setFormData={setFormData} />
            )}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <>
              {completedSteps() === totalSteps() - 3 ? (
                <div className="procced-btn">
                  <Button
                    onClick={handleSendOTP}
                    className="forgot_form_button"
                    style={{ marginLeft: "20px", marginRight: "20px" }}
                  disabled={!formData.username}
                  // size="sm"
                  >
                    Proceed
                  </Button>
                </div>
              ) : completedSteps() === totalSteps() - 2 ? (
                <div className="next-btn">
                  <Button
                    onClick={handleVerifyOTP}
                    className="forgot_form_button"
                    style={{ marginLeft: "20px", marginRight: "20px" }}
                  // size="sm"
                  disabled={!formData.otp}
                  >
                    Next
                  </Button>
                </div>
              ) : (
                <div className="reset-btn">
                  <Button
                    onClick={handleChangePassword}
                    className="forgot_form_button"
                    style={{ marginLeft: "20px", marginRight: "20px" }}
                    // size="sm"
                    disabled={!formData.password || !formData.confirmPassword}
                  >
                    Reset Password
                  </Button>
                </div>
              )}
            </>
          </Box>
          {/* <div className="forgot_form_bottom">
            <h5>Already have an Account.</h5>
            <h5>
              <Link to="/login/" className="link">
                Sign In
              </Link>
            </h5>
          </div> */}
        </React.Fragment>
      )}
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import "./ContactUs.scss";
import { Button, Container } from 'react-bootstrap';
import { Form, Input, Select, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { MdLocationOn, MdOutlineMail } from "react-icons/md";
import { FaPhoneSquareAlt } from 'react-icons/fa';
import { GetConatct, GetServiceNameAPI, PostQuery } from '../../Constants/Api/Api';

const ContactUs = ({path}) => {
    const [service, setService] = useState();
    const [address, setAddress] = useState();

    const [selectedServiceId, setSelectedServiceId] = useState(null);
    const [form] = Form.useForm();

    useEffect(() => {
        GetServiceNameAPI().then((res) => {
            setService(res?.data?.data);
        }).catch((error) => {
            console.error("Error fetching service names:", error);
        });
    }, []);

    const handleServiceSelect = (value) => {
        setSelectedServiceId(value);
    };

    const onFinish = (values) => {
        const formData = {
            name: values.username,
            email: values.useremail,
            contact_number: values.usermobile,
            subject: values.subject,
            messages: values.message,
            service_id: selectedServiceId

        }
        PostQuery(formData).then((res) => {
            message.success(res?.data?.message === "New quote Data Created Successfully." ? "Request Send" : null)
            // form.setFieldsValue();

        }).catch((e) => {
            console.log(e, "error")
        })

        // Here you can perform actions like submitting the form data to a server
    };

    const onFinishFailed = (errorInfo) => {
        console.error('Failed:', errorInfo);
    };

    useEffect(() => {
        GetConatct().then((res) => {
            setAddress(res?.data?.data[0])
        }).catch((error) => {
            console.log(error, "error")
        })
    }, [])
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, [])
    return (
        <div className='ContactUs'>
            {/* <div className='BAnnerImage'>
                <img src={BannerImage} alt='banner-inner' className='banner-inner' />
            </div> */}
            <Container className='main-container'>
                <div className='Contact-us_Main'>
                    <div className='section'>
                        {/* Left Section */}
                        <div className='left'>
                            <h3>Get Fill And Touch With Us..</h3>
                            <h6>Contact us for a free estimate.</h6>
                            <Form
                                name="basic"
                                layout="vertical"
                                initialValues={{
                                    remember: true,
                                }}
                                form={form}

                                autoComplete="off"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                            >
                                {/* Form Items */}
                                <Form.Item
                                    name="username"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your username!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Name" className='custom_input' />
                                </Form.Item>
                                <Form.Item
                                    name="useremail"
                                    rules={[
                                        {
                                            type: 'email',
                                            message: 'The input is not valid E-mail!',
                                        },
                                        {
                                            required: true,
                                            message: 'Please input your E-mail!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Email" className='custom_input' />
                                </Form.Item>
                                <Form.Item
                                    name="usermobile"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your mobile number!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Phone" className='custom_input' />
                                </Form.Item>
                                <Form.Item
                                    name="subject"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input the subject!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Subject" className='custom_input' />
                                </Form.Item>
                                <Form.Item
                                    name="service"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a service!',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select a service"
                                        onChange={handleServiceSelect}
                                    >
                                        {service && service.map((item) => (
                                            <Select.Option key={item.id} value={item.id}>
                                                {item.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="message"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your message!',
                                        },
                                    ]}
                                >
                                    <TextArea
                                        placeholder="Message"
                                        autoSize={{ minRows: 5, maxRows: 5 }}
                                        className='custom_input'
                                    />
                                </Form.Item>
                                {/* Submit Button */}
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className="buttontheme">
                                        Send Message
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>

                        {/* Right Section */}
                        <div className='right'>
                            <h3>Our Address</h3>
                            <h6>We welcome your questions or comments. Feel free to contact us by phone at the number below or by e-mail.</h6>
                            <h6>For a faster response, you can give us a phone call. We look forward to hearing from you.</h6>
                            <div>
                                {/* Address Details */}
                                <div className='addres'>
                                    <div><MdLocationOn /></div>
                                    <div>
                                        <p className='p'>{address?.address}</p>
                                    </div>
                                </div>
                                <div className='addres'>
                                    <div><FaPhoneSquareAlt /></div>
                                    <div>
                                        <p>Phone: <span>{address?.contact_number}</span></p>
                                    </div>
                                </div>
                                <div className='addres'>
                                    <div><MdOutlineMail /></div>
                                    <div>
                                        <p>Email: <span>{address?.email}</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {path !== "home" &&
                <div className='MapSection'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3529.047098862995!2d-68.535687!3d63.752049!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4dd3164431eff40d%3A0x416860c321d32f1!2sNunavut%20Moving%20%26%20Services!5e0!3m2!1sen!2sus!4v1705407307727!5m2!1sen!2sus" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>}
            </Container>
        </div>
    );
};

export default ContactUs;

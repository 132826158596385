import React, { useEffect, useState } from 'react';
import { Row, Tabs } from 'antd';
import { Card, Container } from 'react-bootstrap';
import { BookingHistoryAPI } from '../../Constants/Api/Api';
import "./BookingHistory.scss"
import { FaMapMarkerAlt } from "react-icons/fa";
import { CalendarFilled } from '@ant-design/icons';
import CartImage from "../../assets/delivery-truck.gif"
import { Link } from 'react-router-dom';
const { TabPane } = Tabs;
const BookingHistory = () => {
  const [activeKey, setActiveKey] = useState('ONGOING');
  const [history, setHistory] = useState()
  const onChange = (key) => {
    setActiveKey(key);
  };
  const items = [
    {
      key: 'ONGOING',
      label: 'ONGOING',
      // children: 'Content of ONGOING Tab Pane',
    },
    {
      key: 'COMPLETED',
      label: 'COMPLETED',
      // children: 'Content of COMPLETED Tab Pane',
    },
  ];
  useEffect(() => {
    BookingHistoryAPI(activeKey).then((res) => {
      setHistory(res.data.data)
    }).catch((error) => {
    })
  }, [activeKey])
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [])
  return (
    <Container className='BookingHistory'>
      <Tabs defaultActiveKey={activeKey} onChange={onChange}>
        {items.map((item) => (
          <TabPane tab={item.label} key={item.key} className='tablist'>
            <>
              {history?.length === 0 ? <div className="emptyCart">
                <img src={CartImage} alt="gif" />
                <p>Your History is Empty</p>
                {/* <Link to='/product-shopping'>
                    <button className="shoppingBtn" >Keep Order</button>
                </Link> */}
              </div> :
                history?.map((item, index) => {
                  return (
                    <Card className='BookingHistory_card' key={index}>
                      <Card.Body>
                        <div className='top'>
                          <h4>{item?.booking_id}</h4>
                          {activeKey === "ONGOING" &&
                            <h4 className='approval_Status'>Approval Pending</h4>
                          }
                        </div>
                        <div>
                          <h5>Commercial/Office Moving</h5>
                        </div>
                        <div className='City_one'>
                          <h6><FaMapMarkerAlt className='icon' /></h6>
                          <h6>{item?.pickup_location}</h6>
                        </div>
                        <div className='City_one'>
                          <h6><FaMapMarkerAlt className='icon' /></h6>
                          <h6>{item?.destination_location}</h6>
                        </div>
                        <div className='date'>
                          <h6><CalendarFilled className='icon' /></h6>
                          <h6>{new Date(item?.date).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}, {item?.time}</h6>
                        </div>
                      </Card.Body>
                    </Card>
                  )
                }
                )}
            </>
          </TabPane>
        ))}
      </Tabs>
    </Container>
  );
};
export default BookingHistory;

import React, { useState } from 'react';
import { Modal, message } from 'antd';
import './ProductCard.scss';
import "./ProductDetails.scss"
import DummyProduct from "../../assets/dummy-product.jpg"
import { Image_URL } from '../../Constants/host';
import { AddToCartAPI } from '../../Constants/Api/Api';
import { fetchAllProductsInCart } from '../../redux/cartSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Card } from 'react-bootstrap';
import { CiCirclePlus } from "react-icons/ci";
import { CiCircleMinus } from "react-icons/ci";
const ProductCard = ({ ProductId, Name, Price, Description, Size, Weight, Image, Stock, Cart }) => {
    const [isBookingModalVisible, setBookingModalVisible] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false); // New state for login modal
    const [qnt, setQnt] = useState(1)
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const Userguest = localStorage.getItem("Userguest_id")
    const token = localStorage.getItem("UserLoginToken");
    const handleBookButtonClick = () => {
        setBookingModalVisible(true);
    };
    const handleBookingModalClose = () => {
        setBookingModalVisible(false);
    };
    const handleAddToCart = () => {
        AddToCartAPI(ProductId, qnt, token).then((res) => {
            message.success(res?.data?.message)
            setTimeout(() => {
                navigate("/chekcout-order");
                dispatch(fetchAllProductsInCart(token));
                handleBookingModalClose()
            }, 1000);
        }).catch((error) => {
            message.error(error?.response?.data?.message)
            // Handle error
            // console.log(error)
        })
    }
    const handlegoLogin = () => {
        navigate("/signin")
    }
    const handleAddToCartClick = () => {
        if (Userguest === "LoginUser") {
            handleAddToCart();
        } else {
            setShowLoginModal(true);
            handleBookingModalClose()
            // Show login modal if user is not logged in
        }
    }
    const increaseQuantity = () => {
        setQnt(prevQnt => prevQnt + 1);
    };
    const decreaseQuantity = () => {
        if (qnt > 1) {
            setQnt(prevQnt => prevQnt - 1);
        } else {
            message.error("You need to select at least one quantity.");
        }
    };
    return (
        <>
            <div className='ProductCard'>
                <div className='CardComponent'>
                    <div className='card custom_card' onClick={handleBookButtonClick}>
                        {Image !== undefined ?
                            <img src={`${Image_URL}${Image}`} alt={Name} crossOrigin="anonymous" width="200px" height="200px" class="card-img-top image" /> :
                            <img src={DummyProduct} alt='' width="200px" height="200px" class="card-img-top image" />
                        }
                        {/* <img src={`${Image_URL}/${data?.service_attachments[0]?.file_name}`} crossOrigin='anonymous' class="card-img-top image" alt="..." /> */}
                        <div class="card-body">
                            <h4>{Name}</h4>
                        </div>
                        <div>
                            {Price ?
                                <span className="rated">${Price}</span> :
                                <span className="rated">-</span>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                visible={isBookingModalVisible}
                onCancel={handleBookingModalClose}
                footer={null}
            >
                <div className="ProductDetails">
                    {/* <img src={`${Image_URL}${Image}`} crossOrigin="anonymous" alt='' /> */}
                    {Image !== undefined ?
                        <img src={`${Image_URL}${Image}`} alt={Name} crossOrigin="anonymous" /> :
                        <img src={DummyProduct} alt='' />
                    }
                    <h5>{Name}</h5>
                    <h4>${Price}</h4>
                    <h6>Product Details</h6>
                    <p dangerouslySetInnerHTML={{ __html: Description }}></p>
                    <div className='bottom'>
                        {Cart === 0 || Cart === undefined ? <>
                            <div onClick={decreaseQuantity} className='btn1' >
                                <CiCircleMinus color='#8C0D0D' size={30} />
                            </div>
                            <div className='btn2' >
                                {qnt}
                            </div>
                            <div onClick={increaseQuantity} className='btn3'>
                                <CiCirclePlus color='#8C0D0D' size={30} />
                            </div>
                            <button
                                className='buttontheme'
                                disabled={Stock === 0}
                                onClick={handleAddToCartClick}
                            >
                                {Stock === 0 ? "Out Of Stock" : "Add To Cart"}
                            </button>
                        </>
                            : (Cart &&
                                <button
                                    className='buttontheme'
                                    disabled={Stock === 0 }
                                    onClick={() => navigate("/chekcout-order")}
                                >
                                    {Stock === 0 ? "Out Of Stock" : "Go To Cart"}
                                </button>
                            )
                        }
                    </div>
                </div>
            </Modal>
            {/* Login Modal */}
            <Modal
                visible={showLoginModal}
                onCancel={() => setShowLoginModal(false)}
                footer={null}
            >
                <Card.Body>
                    <Card.Title>Please sign in to add to cart.</Card.Title>
                    <div className='Button_Section'>
                        <Button className='buttontheme' onClick={handlegoLogin}>
                            Login
                        </Button>&nbsp;
                        <Button className='buttontheme' onClick={() => setShowLoginModal(false)}>Cancel</Button>
                    </div>
                </Card.Body>
            </Modal>
        </>
    );
};
export default ProductCard;

import React from "react";
import { Form, Input } from "antd";
import "./Forgot.scss";
const SendOtp = ({ formData, setFormData }) => {
  return (
    <div className="forgot_form">
      <div className="forgot_form_container">
        <Form layout="vertical">
          <Form.Item label="Email">
            <Input
              type="text"
              placeholder="Email"
              value={formData.username}
              onChange={(e) =>
                setFormData({ ...formData, username: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item label="Enter OTP">
            <Input
              type="number"
              placeholder="OTP"
              disabled
            />
          </Form.Item>
          <Form.Item label="Password">
            <Input.Password
              placeholder="New Password"
              disabled
            />
          </Form.Item>
          <Form.Item label="Confirm New Password">
            <Input.Password
              placeholder="Confirm New Password"
              disabled
            />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
export default SendOtp;

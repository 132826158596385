import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import BannerImage from "../../assets/banner-inner.jpg"
import "./BlogDetails.scss"
import { Image_URL } from '../../Constants/host';
import moment from 'moment';
import { GetAllBlogs, GetBlogsById } from '../../Constants/Api/Api';
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md"
import { Skeleton } from 'antd';
const BlogDetails = () => {
    const location = useLocation();
    const [blog, setBlog] = useState()
    const [blogDetails, setBlogDetails] = useState()

    const [loading, setLoading] = useState(true);

    const { data } = location.state || {};
    useEffect(() => {
        GetBlogsById(data).then((res) => {
            setBlogDetails(res?.data?.data)
        }).catch((e) => {
            console.log(e)
        })
        GetAllBlogs(5, 1).then((res) => {
            setBlog(res?.data?.data?.rows)
            setLoading(false);
        }).catch((e) => {
            console.log(e, "error")
        })
    }, [])
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, [])
    return (
        <div className='BlogDetails'>
          
            <Container>
                <Row>
                    <Col sm={8}>
                        <div className='blogdetails_container'>
                            <img class="card-img-top" src={`${Image_URL}${blogDetails?.blog_attachments[0]?.file_name}`} alt="Card image cap" crossOrigin='anonymous' height="170px" width="320px" className='Image' />
                            <div className='details_desc'>
                                <h3>{blogDetails?.heading}</h3>
                                {moment(blogDetails?.created_at).fromNow()}
                                <p class="card-text" className='description_text' dangerouslySetInnerHTML={{ __html: blogDetails?.description.replace(/<h3>&nbsp;<\/h3>/g, '') }}>
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col sm={4}>
                        <div className='blogdetails_container_main'>
                            <div className='full_heading'>
                                <h3>Recent Blogs</h3>
                            </div>
                            {loading ? <Skeleton active /> :
                                <div className='listtt'>
                                    {blog?.map((item, index) => {
                                        return (
                                            <div key={index} >
                                                <li>

                                                    <MdOutlineKeyboardDoubleArrowRight />{item?.heading.split(' ').slice(0, 5).join(' ')}
                                                </li>

                                            </div>
                                        )
                                    })}
                                </div>}
                        </div>

                    </Col>
                </Row>
            </Container>
        </div>

    )
}
export default BlogDetails

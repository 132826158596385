import React, { useEffect, useState } from 'react'
import "./Orders.scss"
import { GetAllOrders } from '../../Constants/Api/Api'
import { Image_URL } from '../../Constants/host'
import { Container } from 'react-bootstrap'
import { Form, Radio, Skeleton } from 'antd'
import { Link } from 'react-router-dom'
import CartImage from "../../assets/ShoppingCart.gif"


const Orders = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        GetAllOrders().then((res) => {
            setOrders(res?.data?.data);
            setLoading(false);
        }).catch((error) => {
            console.log(error, "error");
        });
    }, []);
useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
}, [])
    return (
        <div className='order'>
            <Container className='main-container'>
                <h2>My Orders</h2>
                {orders.length === 0 ? <div className="emptyCart">
                <img src={CartImage} alt="gif" />
                <p>Your Order History is Empty</p>
                <Link to='/product-shopping'>
                    <button className="shoppingBtn" >Keep Order</button>
                </Link>
            </div> :
                orders.map((item, index) => (
                    <div className="card dark" key={index}>
                        {loading ? (
                            // Display skeleton loader for image only
                            <Skeleton.Image active={true} />
                        ) : (
                            // Display the actual image once data is loaded
                            <img
                                src={`${Image_URL}${item?.orderDetails_product?.product_attachments?.[0]?.file_name}`}
                                className="card-img-top"
                                alt=""
                                crossOrigin="anonymous"
                            />
                        )}

                        <div className="card-body">
                            <div className="text-section">
                                <h6 className="card-title order_id">Order Id: {item?.order_id}</h6>
                                <h5 className="card-title">{item?.orderDetails_product?.name}</h5>
                                <p className="card-text">Some quick example text to build on the card's content.</p>
                            </div>
                            <div className="cta-section">
                                <div>Item: {item?.total_items}</div>
                                <div style={{ fontWeight: "bold" }}>Total Price: {item?.total_price}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </Container>
        </div>
    );
};

export default Orders;

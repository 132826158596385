import { Skeleton } from "antd";
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getDatabase, ref, push, set, onValue } from "firebase/database";
import { useEffect, useState } from "react";
// import SendIcon from '@material-ui/icons/Send';
import { Button } from "react-bootstrap";
import { IoMdSend } from "react-icons/io";
import "./Chat.scss"
// Your Firebase config code here...
const firebaseConfig = {
    apiKey: "AIzaSyBrpjWi6rwbL8cp760_U4-g2NEbYKOrP1g",
    authDomain: "nunamoving-mobile-app.firebaseapp.com",
    databaseURL: "https://nunamoving-mobile-app-default-rtdb.firebaseio.com",
    projectId: "nunamoving-mobile-app",
    storageBucket: "nunamoving-mobile-app.appspot.com",
    messagingSenderId: "1062290442356",
    appId: "1:1062290442356:web:7e227d530d365bca172921",
    measurementId: "G-R9M1SYNC4Q"
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getDatabase(app);
function ChatMessage({ message, source, imageUrls }) {
    const messageClass = source === 'SENDER' ? 'sender-message' : 'receiver-message';
    const alignClass = source === 'SENDER' ? 'align-left' : 'align-right';
    return (
        <>{message &&
            <>
                <div className={`message ${messageClass} ${alignClass} messagebox`}>
                    <div className="mes" >
                        {message === "" ? null : message}
                    </div>
                </div>
            </>
        }
            {imageUrls && imageUrls?.map((url, index) => {
                return (
                    <div className={`message ${messageClass} ${alignClass} `}>
                        <div>
                            <img key={index} src={`${url}`} alt={`Image ${index + 1}`} width="280px" height="150px" />
                        </div>
                    </div>
                )
            })}
        </>
    );
}
const Support = () => {
    const [message, setMessage] = useState('');
    const [chatMessages, setChatMessages] = useState([]);
    const [loading, setloading] = useState(true)
    useEffect(() => {
        const messagesRef = ref(db, `CUSTOMER_ADMIN_CHAT/messages/USER_1/ADMIN_8`);
        onValue(messagesRef, (snapshot) => {
            setloading(false)
            const messages = [];
            snapshot.forEach((childSnapshot) => {
                messages.push(childSnapshot.val());
            });
            setChatMessages(messages); // Update chat messages state
        });
    }, [db]);
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, [])
    const sendMessage = async () => {
        try {
            const senderMessageRef = push(ref(db, `CUSTOMER_ADMIN_CHAT/messages/USER_1/ADMIN_8`));
            const receiverMessageRef = push(ref(db, `CUSTOMER_ADMIN_CHAT/messages/ADMIN_8/USER_1`));
            await Promise.all([
                set(senderMessageRef, {
                    message,
                    timestamp: new Date().toISOString(),
                    source: "SENDER",
                    read: true
                }),
                set(receiverMessageRef, {
                    message,
                    timestamp: new Date().toISOString(),
                    source: "RECEIVER",
                    read: false
                })
            ]);
            setMessage('');
        } catch (e) {
            console.error("Error sending message: ", e);
        }
    };
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            sendMessage();
        }
    };
    return (
        <div>
            <div className="chat-container">
                <nav>
                    <h5>Live Support Chat </h5>
                </nav>
                <div className="message-container">
                    {loading ? <Skeleton avatar paragraph={{ rows: 15 }} style={{ padding: "20px" }} /> :
                        chatMessages?.map((msg, index) => (
                            <ChatMessage key={index} message={msg.message} source={msg.source} imageUrls={msg.imageUrls} />
                        ))}
                </div>
                <div className="message">
                    <input
                        type="text"
                        placeholder="Type your message..."
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyPress={handleKeyPress}
                    />
                    <Button className="button_theme" onClick={sendMessage} disabled={!message}>Send <IoMdSend /></Button>
                </div>
            </div>
        </div>
    );
};
export default Support;
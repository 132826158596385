import React from "react";
import { Form, Input } from "antd";
import "./Forgot.scss";

const ChangePassword = ({ formData, setFormData }) => {

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setFormData({ ...formData, password: newPassword });
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setFormData({ ...formData, confirmPassword: newConfirmPassword });
  };

  return (
    <div className="forgot_form">
      <div className="forgot_form_container">
        <Form layout="vertical">

          <Form.Item label="Email">
            <Input
              type="text"
              placeholder={formData.username}
              disabled
            />
          </Form.Item>
          <Form.Item label="OTP">
            <Input
              type="number"
              placeholder={formData.otp}
              disabled
            />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
            hasFeedback
          >
            <Input.Password onChange={handlePasswordChange} />
          </Form.Item>

          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The new password that you entered do not match!'));
                },
              }),
            ]}
          >
            <Input.Password onChange={handleConfirmPasswordChange} />
          </Form.Item>

        </Form>
      </div>
    </div>
  );
};

export default ChangePassword;

import React, { useEffect, useState } from 'react';
import { Container, ListGroup, Nav, NavDropdown, Navbar } from 'react-bootstrap';
import { Avatar, Badge, Dropdown, Menu, Tooltip } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { GetServiceNameAPI } from '../../Constants/Api/Api';
import { HistoryOutlined, InboxOutlined, LogoutOutlined, ShoppingCartOutlined, UserOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import Logo from "../../assets/Logo.png";
import "./HeaderSection.scss";

const HeaderSection = () => {
    const token = localStorage.getItem("UserLoginToken");
    const [service, setServices] = useState();
    const location = useLocation();
    const navigate = useNavigate();

    const handleNavClick = (path) => {
        navigate(path);
        closeNavbar(); // Close the navbar when a navigation item is clicked
    };

    const handleRedirectService = (data) => {
        navigate(`/services/${data?.slug}`, {
            state: {
                data: data,
            }
        });
        closeNavbar(); // Close the navbar when a navigation item is clicked
    };

    useEffect(() => {
        GetServiceNameAPI().then((res) => {
            setServices(res?.data?.data);
        }).catch((error) => {
            console.error("Error fetching services:", error);
        });
    }, []);

    const closeNavbar = () => {
        const navbar = document.querySelector('.navbar-collapse');
        if (navbar.classList.contains('show')) {
            navbar.classList.remove('show');
        }
    };

    const handleMenuClick = (e) => {
        if (e.key === 'profile') {
            navigate('/profile-edit');
        } else if (e.key === 'logout') {
            localStorage.removeItem("UserLoginToken")
            localStorage.removeItem("Userguest_id")
            navigate('/');
        }
        else if (e.key === 'booking-history') {
            navigate('/booking-history');
        }
        else if (e.key === 'orders') {
            navigate('/orders');
        }
        closeNavbar(); // Close the navbar when a menu item is clicked
    };

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="profile">
                <UserOutlined /> &nbsp;
                Profile
            </Menu.Item>
            <Menu.Item key="booking-history">
                <HistoryOutlined />&nbsp;
                Booking History
            </Menu.Item>
            <Menu.Item key="orders">
                <InboxOutlined />&nbsp;
                Orders
            </Menu.Item>
            <Menu.Item key="logout">
                <LogoutOutlined />&nbsp;
                Logout
            </Menu.Item>
        </Menu>
    );

    const product = useSelector((state) => state.cartReducerData.products?.data?.cartItems);

    return (
        <div className='HeaderSection'>
            <div className='TopStrip'>
                <Container>
                    <a href="tel:867979-0707" className='link'>CALL US TODAY:867-979-0707</a>
                </Container>
            </div>
            <Navbar expand="lg" className="bg-body-tertiary customnav">
                <Container>
                    <Navbar.Brand onClick={() => handleNavClick("/")} className='customnav'>
                        <img
                            src={Logo}
                            alt="Logo"
                            className="d-inline-block align-top Logo"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav>
                            <Nav.Link onClick={() => handleNavClick("/")}>HOME</Nav.Link>
                            <Nav.Link onClick={() => handleNavClick("/about-us")}>ABOUT US</Nav.Link>
                            <NavDropdown title="SERVICES" id="nav-dropdown">
                                <ListGroup variant="flush">
                                    {service?.map((item, index) => (
                                        <ListGroup.Item
                                            className={`${location.pathname.includes(`/services/${item.slug}`) ? 'active' : ''}`}
                                            key={index}
                                            onClick={() => handleRedirectService(item)}
                                        >
                                            <div className='itemsName'>
                                                {item.name}
                                            </div>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </NavDropdown>
                            <Nav.Link onClick={() => handleNavClick("/blogs")}>BLOG</Nav.Link>
                            <Nav.Link onClick={() => handleNavClick("/product-shopping")}>SHOP</Nav.Link>
                            <Nav.Link onClick={() => handleNavClick("/contact-us")}>CONTACT US</Nav.Link>
                            <Nav.Link>
                                <div className='request_quote'>
                                    REQUEST A QUOTE
                                </div>
                            </Nav.Link>
                            {!token &&
                                <Nav.Link onClick={() => handleNavClick("/signin")}>
                                    <div className='request_quote'>
                                        LOGIN
                                    </div>
                                </Nav.Link>
                            }
                            {token &&
                                <>
                                    <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
                                        <Tooltip title="Account settings">
                                            <Avatar
                                                size={32}
                                                icon={<UserOutlined />}
                                                style={{ textTransform: 'capitalize', marginLeft: 8, cursor: 'pointer' }}
                                            >
                                                B
                                            </Avatar>
                                        </Tooltip>
                                    </Dropdown>
                                    <Link to="/chekcout-order" className='link'onClick={closeNavbar}>
                                        <Badge color="secondary" count={product?.length}>
                                            <Avatar
                                                size={32}
                                                icon={<ShoppingCartOutlined />}
                                                style={{ textTransform: 'capitalize', marginLeft: 8, cursor: 'pointer' }}
                                            />
                                        </Badge>
                                    </Link>
                                </>
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
};

export default HeaderSection;

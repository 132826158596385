import React from "react";
import "./signin.scss";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Input, message } from 'antd';
import { Container } from "react-bootstrap";
import { UserLoginAPI } from "../../Constants/Api/Api";
const Signin = () => {
  const navigate = useNavigate()
  const onFinish = (values) => {
    let formData = {
      email: values.userenamil,
      password: values.password,
    }
    UserLoginAPI(formData).then((res) => {

      message.success(res?.data?.message)
      setTimeout(() => {
        const Userguest = res?.data?.data?.guest_id ? null : "LoginUser"
        const token = res?.data?.data?.tokens?.access.token;
        localStorage.removeItem("UserLoginToken");
        localStorage.removeItem("GuestLoginToken");
        localStorage.setItem("UserLoginToken", token);
        localStorage.removeItem("Userguest_id");
        localStorage.setItem("Userguest_id", Userguest);
        navigate("/");
      }, 1000);
    }).catch((error) => {
      console.log(error, "error")
      message.error(error?.response?.data?.message)
      // if (error?.response?.data?.message === 'Error: Customer not found. Please verify otp First') {
      //   message.error("Please verify otp First")
      // }
    })
  };
  const onFinishFailed = (errorInfo) => {
  };

  return (
    <>
   
      <Container className="Signin">
        <div className="Main" >
      

          <h3>SignIn</h3>
            <div className="form_inner">

              <Form
                name="basic"
                layout="vertical"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label="User Email"
                  name="userenamil"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your useremail!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              
                <Form.Item
                >
                  <p
                    onClick={() => {
                      navigate("/forgot-password")
                    }}
                    className="forgotpasswordtext"
                  >
                    Forgot password?
                  </p>
                  <Button htmlType="submit" className="buttontheme">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
            <div className="bottom_text">
              <div>
                I’m a new user.? <Link to="/signup">
                  Sign Up
                </Link>
                
              </div>
            </div>
          </div>
      
      </Container>
    </>
  );
};
export default Signin;

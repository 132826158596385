import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import "./ServiceDetails.scss"
import { Col, Container, Row } from 'react-bootstrap'
import { ServiceDetailsAPI, UserLoginAsGuestAPI } from '../../Constants/Api/Api'
// import BookingComponent from '../BookingComponent/BookingComponent'
import { Skeleton, message } from 'antd'
import { Image_URL } from '../../Constants/host'
import BookingComponent from '../BookingComponent/BookingComponent'
const ServiceDetails = () => {
    const location = useLocation();
    const { data } = location.state || {};
    const [serviceDeatils, setServiceDetails] = useState()
    const [isBookingModalVisible, setBookingModalVisible] = useState(false);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        ServiceDetailsAPI(data?.slug).then((res) => {
            setLoading(false);
            setServiceDetails(res.data.data)
        }).catch((error) => {
        })
    }, [data?.slug])
    const handleBookButtonClick = () => {
        // Open the booking modal
        setBookingModalVisible(true);
    };
    const handleBookingModalClose = () => {
        // Close the booking modal
        setBookingModalVisible(false);
    };
    const navigate = useNavigate()
    const Userguest = localStorage.getItem("Userguest_id")
    const handleLoginASGuest = () => {
        UserLoginAsGuestAPI().then((res) => {
            setTimeout(() => {
                const token = res.data.data.tokens.access.token;
                localStorage.removeItem("GuestLoginToken");
                localStorage.setItem("GuestLoginToken", token);
                localStorage.removeItem("Userguest_id");
                localStorage.setItem("Userguest_id", "GuestLogin");
            }, 1000);
        }).catch((error) => {
        })
    }
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, [])
    return (
        <div className='ServiceDetails'>

            <Container className='ServiceContent'>
                <Row className='Section'>
                    <Col className='left'>
                        <img src={`${Image_URL}/${data?.service_attachments[0]?.file_name}`} crossOrigin='anonymous' alt='' />
                    </Col>
                    <Col className='right'>
                        {loading ? <Skeleton active /> : <>
                            <h5>{serviceDeatils?.name}</h5>
                            <button className='buttontheme2'
                                onClick={() => { handleBookButtonClick(); { !Userguest && (Userguest !== "LoginUser" || Userguest !== "GuestLogin") && handleLoginASGuest() } }}
                            >BOOK US</button>
                            <BookingComponent isVisible={isBookingModalVisible} onClose={handleBookingModalClose} serviceID={data?.id} />
                            <p dangerouslySetInnerHTML={{ __html: serviceDeatils?.description }}></p>
                        </>}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default ServiceDetails

import React, { useEffect } from 'react';
import { Button, DatePicker, Form, Input, Modal, Space, TimePicker, message } from 'antd';
import "./BookingComponent.scss"
import dayjs from 'dayjs';
import { CreateBookingWithLoginAPI, CreateBookingWithoutLoginAPI } from '../../Constants/Api/Api';
const SubmitButton = ({ form }) => {
    const [submittable, setSubmittable] = React.useState(false);
    const values = Form.useWatch([], form);
    React.useEffect(() => {
        form
            .validateFields({
                validateOnly: true,
            })
            .then(
                () => {
                    setSubmittable(true);
                },
                () => {
                    setSubmittable(false);
                },
            );
    }, [values]);
    return (
        <Button type="primary" htmlType="submit" disabled={!submittable} className='button_theme'

        >
            Book Your Service
        </Button>
    );
};
const BookingComponent = ({ isVisible, onClose, serviceID }) => {
    const token = localStorage.getItem("UserLoginToken")
    const GuestToken = localStorage.getItem("GuestLoginToken")

    const Userguest = localStorage.getItem("Userguest_id")
    const [form] = Form.useForm();
    const onChange = (date, dateString) => {
    };
    const resetForm = () => {
        form.resetFields();
    };
    const onFinish = (values) => {
        const formattedDate = dayjs(values.date).format('YYYY-MM-DD');
        const formattedTime = dayjs(values.time).format('HH:mm');
        if (Userguest === "LoginUser") {
            let formData = {
                service_id: serviceID,
                date: formattedDate,
                time: formattedTime,
                pickup_location: values.pickuplocation,
                destination_location: values.destinationlocation
            }

            onClose()
            CreateBookingWithLoginAPI(formData, token).then((res) => {
                message.success(res?.data?.message)

            }).catch((error) => {
                console.log(error, "error")
            })
        } else {
            let formData = {
                service_id: serviceID,
                date: formattedDate,
                time: formattedTime,
                pickup_location: values?.pickuplocation,
                destination_location: values?.destinationlocation,
                name: values?.username,
                email: values?.userenamil,
                contact_number: values?.userphone
            }
            onClose()
            CreateBookingWithoutLoginAPI(formData, GuestToken).then((res) => {

                message.success(res?.data?.message)

            }).catch((error) => {
                console.log(error, "error")

            })
        }
    };
    const format = 'HH:mm';
    return (
        <Modal visible={isVisible} onCancel={onClose} footer={null}>
            <div className='BookingComponent'>
                <h5>Residential Moving Services
                </h5><h6>(Book Us for a Smooth Move)</h6>
                <Form form={form} name="validateOnly" layout="vertical" autoComplete="off" onFinish={onFinish} className='form_layout'>
                    {Userguest !== "LoginUser" && (<>
                        <Form.Item
                            name="username"
                            label="User Name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your username!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="User Email"
                            name="userenamil"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your useremail!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="User Phone"
                            name="userphone"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your userphone!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </>
                    )}
                    <Form.Item
                        name="pickuplocation"
                        label="Pickup Location"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="destinationlocation"
                        label="Destination Location"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Space >
                        <Form.Item
                            name="date"
                            label="Select Date"
                            // style={{ width: '50%' }}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <DatePicker onChange={onChange} />
                        </Form.Item>
                        <Form.Item
                            name="time"
                            label="Select Time"
                            // style={{ width: '50%' }}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            {/* <TimePicker format={format} /> */}
                            <TimePicker minuteStep={15} use12Hours format="h:mm a" />
                        </Form.Item>
                    </Space>
                    <Form.Item>
                        <Space>
                            <SubmitButton form={form} />
                            <Button htmlType="button" onClick={resetForm} className='button_theme'>
                                Reset
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
};
export default BookingComponent;

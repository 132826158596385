import React, { useEffect, useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import BannerImage from "../../assets/banner-inner.jpg"
import "./Blogs.scss"
import { GetAllBlogs } from '../../Constants/Api/Api'
import { Image_URL } from '../../Constants/host'
import usePagination from '../Pagination/Pagination'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { Skeleton } from 'antd'
import BlogCardComponent from '../CardComponent/BlogCardComponent'
// import Signin from '../Signin/Signin'
const Blogs = () => {
    const token = localStorage.getItem("UserLoginToken");
    const [blog, setBlog] = useState()
    const navigate = useNavigate()
    let [page, setPage] = useState(1);
    const PER_PAGE = 12;
    const [Data, setData] = useState([]);
    const [data_count, setCount] = useState();
    const [loading, setLoading] = useState(true);
    // const count = Math.ceil(allData.length / PER_PAGE);
    const _DATA = usePagination(Data, PER_PAGE);
    const handleChange = (e, p) => {
        setPage(p);
        _DATA.jump(p);
    };
    // BlogByCategoryApi(slug, PER_PAGE, page)
    useEffect(() => {
        GetAllBlogs(PER_PAGE, page).then((res) => {
            setBlog(res?.data?.data?.rows)
            setLoading(false);
        }).catch((e) => {
            console.log(e, "error")
        })
    }, [PER_PAGE, page])
    const handleRedirectBlogDetails = (data) => {
        navigate("/blogs-details", {
            state: {
                data: data,
            }
        })
    }
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, [])
    return (
        <>
            <div className='Blogs'>
                <Container className='BlogsSection'>
                    <div class="row">
                        {loading ? (
                            Array.from({ length: 3 }, (_, index) => (
                                <div class="col-sm-4" key={index}>
                                    <Skeleton.Image active={true} />
                                </div>
                            ))
                        ) :
                            blog?.map((item, index) => {
                                return (
                                    <div class="col-sm-4 " key={index}>
                                        <BlogCardComponent  Image={`${Image_URL}${item?.blog_attachments[0]?.file_name}`} Title={item?.heading.split(' ').slice(0, 3).join(' ')} Date={moment(item?.created_at).fromNow()} Description={item?.description?.replace(/<h3>&nbsp;<\/h3>/g, '').split(/\s+/).slice(0, 35).join(' ')} blogId={item?.id}/>
                                    </div>
                                )
                            })}
                    </div>
                </Container>
            </div>
        </>
    )
}
export default Blogs
